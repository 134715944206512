.restructuring {
  background-image: url(/Users/aicgim-imac/Desktop/webashpak/src/images/pexels-scott-webb-3255761.jpg);
  height: 40vh;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.row1 {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.row2 {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.row3 {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}
