input[type="text"],
select {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.ashpak {
  border-radius: 5px;
  background-color: #f2f2f2;
  padding: 20px;
  margin-top: 70px;
  margin-bottom: 70px;
}
