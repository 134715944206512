.head {
  margin-top: 50%;
}

.aboutUsSection {
  margin-top: 70px;
  background-image: url(/Users/aicgim-imac/Desktop/webashpak/src/images/john-towner-3Kv48NS4WUU-unsplash.jpg);
  height: 70vh;
  /* background-position: center; */
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.para {
  text-decoration: none !important;
}
