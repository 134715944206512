.ashpaknadaf {
  /* box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  padding-top: 40px;
  padding-bottom: 40px; */
  box-shadow: rgba(27, 31, 35, 0.04) 0px 1px 0px,
    rgba(255, 255, 255, 0.25) 0px 1px 0px inset;
  padding-top: 40px;
  padding-bottom: 40px;
  padding-left: 40px;
  cursor: pointer;
}

.ashpaknadaf1 {
  box-shadow: rgba(27, 31, 35, 0.04) 0px 1px 0px,
    rgba(255, 255, 255, 0.25) 0px 1px 0px inset;
  padding-top: 40px;
  padding-bottom: 40px;
  padding-left: 40px;
  cursor: pointer;
}

.ashpaknadaf2 {
  box-shadow: rgba(27, 31, 35, 0.04) 0px 1px 0px,
    rgba(255, 255, 255, 0.25) 0px 1px 0px inset;
  padding-top: 40px;
  padding-bottom: 40px;
  padding-left: 40px;
  cursor: pointer;
}

.ashpak1 {
  box-shadow: rgba(27, 31, 35, 0.04) 0px 1px 0px,
    rgba(255, 255, 255, 0.25) 0px 1px 0px inset;
  padding-top: 40px;
  padding-bottom: 40px;
  padding-left: 40px;
  cursor: pointer;
}

.ashpak2 {
  box-shadow: rgba(27, 31, 35, 0.04) 0px 1px 0px,
    rgba(255, 255, 255, 0.25) 0px 1px 0px inset;
  padding-top: 40px;
  padding-bottom: 40px;
  padding-left: 40px;
  cursor: pointer;
}

.ashpak3 {
  box-shadow: rgba(27, 31, 35, 0.04) 0px 1px 0px,
    rgba(255, 255, 255, 0.25) 0px 1px 0px inset;
  padding-top: 40px;
  padding-bottom: 40px;
  padding-left: 40px;
  cursor: pointer;
}

.helloworld {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px;
}
